<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import axios from 'axios'
export default{
  mounted() {
    axios.post("https://h5api.xiaoxxx.cn/h5/dreamH5/getJsSDKConfig", { url: location.href.split("#")[0], }).then((res)=> {
      if (res.status === 200) {
        let JsSDKConfig = res.data;
        window.wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false,
          appId: JsSDKConfig.appId,
          timestamp: JsSDKConfig.timestamp,
          nonceStr: JsSDKConfig.nonceStr,
          signature: JsSDKConfig.signature,
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData",
          ]
        });

        window.wx.ready(function () {
          // 转发
          window.wx.updateAppMessageShareData({
            title: "2024孕婴童数字营销手册", // 分享标题
            desc: "速领行业攻略、营销工具、全年营销日历等", // 分享描述
            link: "https://cbme.xiaoxxx.com/#/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://image.xiaoxxx.cn/test/urlGather/2024071693dd366fe71b4ef2284668.jpg", // 分享图标
            success: function () { },
          });
          // 分享朋友圈
          window.wx.updateTimelineShareData({
            title: "2024孕婴童数字营销手册", // 分享标题
            link: "https://cbme.xiaoxxx.com/#/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://image.xiaoxxx.cn/test/urlGather/2024071693dd366fe71b4ef2284668.jpg", // 分享图标
            success: function () { },
          });
        });
      }
    });
  },
}
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 7.5rem;
    position: relative;
    margin: 0 auto;
    height: 16.24rem;
    max-height: 16.24rem;
    min-height: 13.34rem;
    overflow: hidden;
    background: url('https://image.xiaoxxx.cn/test/urlGather/20240715375bc274000f4862501456.jpg') no-repeat center;
    background-size: cover;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
i,
dl,
dt,
dd,
ol,
ul,
li,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
}

ol,
ul,
li {
    list-style: none;
}

html,
body {
    width: 100%;
    height: 100vh;
    font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", "微软雅黑",
        sans-serif;
    overflow: hidden;
}

body {
    line-height: 1;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
    -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
    width: 0px;
}
.code-an{
  animation: codean 3s linear infinite;
}
@keyframes codean{
  0%{transform: translate(-50%,0);}
  25%{transform: translate(-50%,10px);}
  50%{transform: translate(-50%,0);}
  75%{transform: translate(-50%,-10px);}
  100%{transform: translate(-50%,0);}
}
* {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
    not supported by any browser */
}
.main{
  height: 100vh;
  min-height: 13.34rem;
  max-height: 16.24rem;
}
</style>

<style scoped>
    .list{
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .top{
        width: 100%;
        height: 3.11rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/202407150fd351c0f139fcb0242615.png') no-repeat center;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
    }
    .title{
        width: 2.85rem;
        height: 1.2rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240715efe6b319b6b5f232943446.png') no-repeat center;
        background-size: contain;
        position: absolute;
        right: 0.77rem;
        top: 1.15rem;
    }
    .logo{
        width: 2.46rem;
        height: 0.38rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/2024071645723e0b66c5a0e4648299.png') no-repeat center;
        background-size: contain;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.5rem;
    }
    .more{
        width: 1.91rem;
        height: 0.45rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240716c42b74eb6f633966289430.png') no-repeat center;
        background-size: contain;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 9.3rem;
        animation: morean 1.5s linear infinite;
    }
    @keyframes morean{
        0%{transform: translateX(-50%) scale(1);}
        50%{transform: translateX(-50%) scale(1.1);}
        100%{transform: translateX(-50%) scale(1);}
    }
    .content{
        width: 6.7rem;
        height: 8rem;
        position: absolute;
        left: 50%;
        top: 2.8rem;
        transform: translateX(-50%);
    }
    .list-title{
        width: 3.36rem;
        height: 2.38rem;
        position: absolute;
    }
    .title1{
        background: url('https://image.xiaoxxx.cn/test/urlGather/2024071531d5fd35bf34ea10853137.png') no-repeat center;
        background-size: contain;
        left: 0;
        top: 0.5rem;
        /* transform: translateX(-5rem); */
    }
    .title3{
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240715efc061154ae548b0964127.png') no-repeat center;
        background-size: contain;
        left: 0;
        top: 2.9rem;
        /* transform: translateX(-5rem); */
    }
    .title5{
        background: url('https://image.xiaoxxx.cn/test/urlGather/202407150083789b70d9cca7881688.png') no-repeat center;
        background-size: contain;
        left: 0;
        top: 5.3rem;
        /* transform: translateX(-5rem); */
    }
    .title2{
        background: url('https://image.xiaoxxx.cn/test/urlGather/2024071590cbac4b1563ac01227335.png') no-repeat center;
        background-size: contain;
        right: 0;
        top: 1.1rem;
        /* transform: translateX(5rem); */
    }
    .title4{
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240715968ba3eb959a4089363097.png') no-repeat center;
        background-size: contain;
        right: 0;
        top: 3.5rem;
        /* transform: translateX(5rem); */
    }
    .title6{
        background: url('https://image.xiaoxxx.cn/test/urlGather/2024071566724273782005f8299788.png') no-repeat center;
        background-size: contain;
        right: 0;
        top: 5.9rem;
        /* transform: translateX(5rem); */
    }
    .title1-an{
        animation: titleleftan 0.5s ease forwards;
    }
    .title2-an{
        animation: titlerightan 0.5s 0.1s ease forwards;
    }
    .title3-an{
        animation: titleleftan 0.5s 0.2s ease forwards;
    }
    .title4-an{
        animation: titlerightan 0.5s 0.3s ease forwards;
    }
    .title5-an{
        animation: titleleftan 0.5s 0.4s ease forwards;
    }
    .title6-an{
        animation: titlerightan 0.5s 0.5s ease forwards;
    }
    @keyframes titleleftan{
        0%{transform: translateX(-5rem);}
        100%{transform: translateX(0);}
    }
    @keyframes titlerightan{
        0%{transform: translateX(5rem);}
        100%{transform: translateX(0);}
    }
</style>
<template>
    <div class="list main">
        <div class="top"></div>
        <div class="title"></div>
        <div class="content">
            <div class="logo"></div>
            <div class="more" @click="nextMore"></div>
            <div class="list-title title1" @click="nextPage(2)"></div>
            <div class="list-title title2" @click="nextPage(6)"></div>
            <div class="list-title title3" @click="nextPage(7)"></div>
            <div class="list-title title4" @click="nextPage(9)"></div>
            <div class="list-title title5" @click="nextPage(11)"></div>
            <div class="list-title title6" @click="nextPage(13)"></div>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        activeIndex:{
            type:Number,
            default:1
        }
    },
    methods: {
        nextPage(index) {
            this.$emit('jumpPage', index)
        },
        nextMore(){
            window.location.href = 'http://www.xiaoxxx.cn/#/'
        }
    },
}
</script>
<style scoped>
  .slide{
    width: 100%;
    position: relative;
    overflow: hidden;
    font-size: 20px;
  }
  .swiper-container,.swiper-wrapper,.swiper-slide{
    width: 100%;
  }
  .return-list{
    position: absolute;
    right: 0;
    top: 0.5rem;
    z-index: 100;
    width: 2.3rem;
    height: 0.52rem;
    background: url('https://image.xiaoxxx.cn/test/urlGather/2024071506dc0cb3d0a681ef885785.png') no-repeat center;
    background-size: contain;
  }
  .tips{
    width: 0.98rem;
    height: 0.98rem;
    background: url('https://image.xiaoxxx.cn/test/urlGather/202407152b0a6f2bbb1c82e5857299.png') no-repeat center;
    background-size: contain;
    position: absolute;
    left: 50%;
    bottom: 0.3rem;
    transform: translateX(-50%);
    z-index: 1000;
    animation: tipsan 2s linear infinite;
  }
  @keyframes tipsan{
    0%{transform: translate(-50%,0);}
    25%{transform: translate(-50%,5px);}
    50%{transform: translate(-50%,0);}
    75%{transform: translate(-50%,-5px);}
    100%{transform: translate(-50%,0);}
  }
</style>
<template>
  <div class="slide main">
    <div class="swiper-container main" id="swiper-container1">
      <div class="swiper-wrapper main">
        <div class="swiper-slide">
          <IndexView />
        </div>
        <div class="swiper-slide">
          <ListView @jumpPage="handleJumpPage" />
        </div>
        <div class="swiper-slide">
          <TacticsOne />
        </div>
        <div class="swiper-slide">
          <TacticsTwo />
        </div>
        <div class="swiper-slide">
          <TacticsThree />
        </div>
        <div class="swiper-slide">
          <TacticsFour />
        </div>
        <div class="swiper-slide">
          <CalendarView @closeTips="closeTips" />
        </div>
        <div class="swiper-slide">
          <AtmosphereView />
        </div>
        <div class="swiper-slide">
          <AtmosphereCase />
        </div>
        <div class="swiper-slide">
          <ActivityView />
        </div>
        <div class="swiper-slide">
          <ActivityCase />
        </div>
        <div class="swiper-slide">
          <ProductView />
        </div>
        <div class="swiper-slide">
          <ProductCase />
        </div>
        <div class="swiper-slide">
          <ResultView />
        </div>
      </div>
    </div>
    <div class="return-list" @click="handleJumpPage(1)" v-if="activeIndex!==0&&activeIndex!==1&&isTips"></div>
    <div class="tips" v-if="isDown" @click="nextSlide"></div>
  </div>
</template>

<script>
import ActivityView from "@/components/ActivityView.vue";
import ActivityCase from "@/components/ActivityCase.vue";
import AtmosphereView from "@/components/AtmosphereView.vue";
import AtmosphereCase from "@/components/AtmosphereCase.vue";
import CalendarView from "@/components/CalendarView.vue";
import IndexView from "@/components/IndexView.vue";
import ListView from "@/components/ListView.vue";
import ProductView from "@/components/ProductView.vue";
import ProductCase from "@/components/ProductCase.vue";
import ResultView from "@/components/ResultView.vue";
import TacticsOne from "@/components/TacticsOne.vue";
import TacticsTwo from "@/components/TacticsTwo.vue";
import TacticsThree from "@/components/TacticsThree.vue";
import TacticsFour from "@/components/TacticsFour.vue";
export default {
  name: 'HomeView',
  components: {
    ActivityView,
    ActivityCase,
    AtmosphereView,
    AtmosphereCase,
    CalendarView,
    ProductCase,
    IndexView,
    ListView,
    ProductView,
    ResultView,
    TacticsOne,
    TacticsTwo,
    TacticsThree,
    TacticsFour
  },
  data() {
    return {
      activeIndex:0,
      swiper:null,
      isDown:true,
      isTips:true
    }
  },
  mounted(){
    this.swiper =new window.Swiper('#swiper-container1', {
      direction: 'vertical',
      on:{
        slideChangeTransitionStart: ()=>{
          this.activeIndex = this.swiper.activeIndex;
          console.log(this.activeIndex)
          if(this.swiper.isEnd){
            this.isDown = false
          }else{
            this.isDown = true
          }
        }
      }
    })
  },
  methods:{
    handleJumpPage(index){
      this.activeIndex = index
      this.swiper.slideTo(index, 0, false);
    },
    closeTips(data){
      this.isDown = data
      this.isTips = data
    },
    nextSlide(){
      this.swiper.slideNext();
    }
  }
}
</script>
